import { includes } from 'ramda';

export const version = '2.2.22';
export const GAC = atob('R2V0IEEgQ29weXdyaXRlcg==');        //Get A Copywriter
export const domainGAC = atob('Z2V0YWNvcHl3cml0ZXI=');      //getacopywriter
export const epiic = atob('ZXBpaWM=');                      //epiic
export const Epiic = atob('RXBpaWM=');                      //Epiic

const href = window.location.href;
const isLocal = href.includes('localhost');
export const isAppAm = href.includes(`app-am.${domainGAC}`) || href.includes(`app-am.${epiic}`);
export const isAppDev = href.includes(`app-dev.${domainGAC}`) || href.includes(`app-dev.${epiic}`);
export const isAppQa = href.includes(`app-qa.${domainGAC}`) || href.includes(`app-qa.${epiic}`);
export const isAppYv = href.includes(`app-yv.${domainGAC}`) || href.includes(`app-yv.${epiic}`);
export const isAppVi = href.includes(`app-vi.${domainGAC}`) || href.includes(`app-vi.${epiic}`);
export const isAppRa = href.includes(`app-ra.${domainGAC}`) || href.includes(`app-ra.${epiic}`);
export const isAppOcean = href.includes(`app-ocean.${domainGAC}`) || href.includes(`app-ocean.${epiic}`);
export const isAppBuild = href.includes(`app-build.${domainGAC}`) || href.includes(`app-build.${epiic}`);
const isWL = href.includes('whitelabel-dev.qwriter.com');
const isCropl = href.includes('content-dev.cropl.com');
const isFileProtocol = window.location.protocol.includes('file');

// Список stage доменов white label. Для них API Url будет api-dev.qwriter.com
const whitelabelDevDomains = ['qa.snowtech.work', 'vffk.snowtech.work', 'content-dev.cropl.com'];
// Весь домен, вместе со 2ым и т.д. уровнями. Например app.epiic.com. Для localhost будет localhost
export const fullDomain = window.location.hostname;
// Основной домен. Например для app.epiic.com будет epiic.com. Для localhost будет localhost
export const primaryDomain = fullDomain.split('.').length === 1 ? fullDomain : fullDomain.split('.').slice(-2).join('.');

export const isStage = isLocal || isAppBuild || isAppAm || isAppDev || isAppQa || isAppYv || isAppVi || isAppRa || isWL || isCropl || isAppOcean || isFileProtocol;
const url = isAppVi || isAppBuild ? 'api-vi' : isLocal ? 'api-vi' : isAppOcean ? 'api-ocean' : isAppDev || isWL || isCropl ? 'api-dev' : isAppYv ? 'api-yv' : isAppQa ? 'api-qa' : isAppRa ? 'api-ra' : 'api';
export const domain = includes(epiic, window.location.href) ? `${epiic}.com` : !includes(domainGAC, href) && !isLocal ? 'qwriter.com' : `${domainGAC}.com`;
export const authStr = atob('R2V0QUNvcHl3cml0ZXI=');
export const favicon = 'https://21237f0bdb7ac007984e-064f4d8f508cdd069aa21a9d8826cc70.ssl.cf2.rackcdn.com/favicon.ico';
export const epiicFavicon = 'https://b5d604f7c1834f57e1de-d19703848730a7b8a25ade40925c2032.ssl.cf2.rackcdn.com/epiic-favicon.ico';
export const api = whitelabelDevDomains.includes(fullDomain) ? 'https://api-dev.qwriter.com' : `https://${url}.${domain}`;
export const stripeKey = isStage ? 'pk_test_y3N45B8rQcdCnL7P0iNDMWAd' : 'pk_live_oiQBBChH0dklWaExCFbvQQxs';
export const stripeKeyEpiic = isStage ? 'pk_test_KMWQ0qERxs6eAEPPWgTuC2Ap' : 'pk_live_5ef6i78J2m4nUah907uprK06';
export const paypalKey = isStage ? 'AfOyaGBPO4LJqn2qgta_jybNxDYE8TYvz7jJmiMXVfULQrOxs1m0OhWpdsKECHPLEoA2WZN8c5vAbxs4' : 'AZi6yy7T0BVhHDZXtWFIqzWhboDmeyRE6VZ4S42koVhNzMMMK8BTXMZAa2EF70dhuDWjyx_L8K9HKc0g';
export const pusherKey = isStage ? '3f875e69198a8bbe07b8' : '04cf4a11b3c57a84be37';
export const pusherCluster = 'us2';
export const captchaSiteKey = '6LdlkksUAAAAAK0df-0T272wB16q_e530qC7-4eN';
export const captchaSiteKeyModal = '6Le5TZ0UAAAAAMoN16ICZTd0KcGWbOEvFwn8slZE';

// ID: Google Tag Manager
// export const getGtmId = (host) => isEpiic(host) ? 'GTM-THBH537' : 'GTM-PFZNGP3';
// export const getGAdsId = (host) => isEpiic(host) ? 'AW-416437527' : 'AW-990463297'; // Ads
// ID: Google Analytics 4
export const getGA4Id = () => includes(domainGAC, window.location.href) ? 'G-LYPC2S5C39' : 'G-PM06612JR6';
// export const getGoogleAdsId = (host) => isEpiic(host) ? 'AW-416437527/kUGECOTc3PYBEJeqycYB' : 'AW-990463297/FXwRCP-khwUQwYql2AM';
// ID: Facebook Pixel
export const fbPixelId = '388945984608373';
export const fbPixelIdEmployees = '631590655680890';
// Crisp ID
export const getCrispId = () => '0cf90020-5b39-47f3-84c8-87c126f600de';
export const getKeys = (state) => state.auth.keys;
export const getTalents = (state) => state.user.talents;
export const getUserId = (state) => state.user.details.user_id;
export const getUserDetails = (state) => state.user.details;
export const getAccounts = (state) => state.user.details.accounts;
export const getActiveSub = (state) => state.user.activeSub;
export const getActiveBloggingSub = (state) => state.user.activeBloggingSub;
export const getActiveDesignSub = (state) => state.user.activeDesignSub;
export const getActiveWebBuildingSub = (state) => state.user.activeWebBuildingSub;
export const getActiveAssistantsSub = (state) => state.user.activeAssistantsSub;
export const getActiveBundle = (state) => state.user.activeBundle;
export const getCompanyBranding = (state) => state.user.company.branding;
export const getCompanyBilling = (state) => state.user.company.billing;
export const getCompanySubs = (state) => state.user.company.subscriptions;
export const getProposal = (state) => state.user.proposal;
export const getBrandProfiles = (state) => state.user.brandProfiles;
export const getOrderState = (state) => state.order;
export const getForms = (state) => state.forms;
export const getOrderList = (state) => state.lists.orderList;
export const getStaticData = (state) => state.lists.staticData;
export const getCurrencies = (state) => state.lists.staticData.currencies;
export const getBundles = (state) => state.lists.staticData.bundles;
export const getRates = (state) => state.lists.staticData.rates;
export const getSubs = (state) => state.lists.staticData.subscriptions;
export const getMessages = (state) => state.lists.messages;
export const getUsers = (state) => state.lists.users;
export const getProjects = (state) => state.lists.projects;
export const getTags = (state) => state.project.tags;
export const getProjectDetails = (state) => state.project.details;
export const getPusherData = (state) => state.socket.pusher;
export const getPaymentsType = (state) => state.payments.type;
export const getMaintenanceMode = (state) => state.ui.isMaintenance;
export const getInitPath = (state) => state.ui.initPath;
export const getInitSearch = (state) => state.ui.initSearch;
export const getWhiteLabel = (state) => state.ui.isWhiteLabel;
export const getRef = (state) => state.ui.reference;
export const getBundlesRef = (state) => state.ui.bundlesRef;
export const getAuth = (state) => state.ui.isAuth;
export const getLoginAttempt = (state) => state.ui.loginAttempt;
export const getIsMobile = (state) => state.ui.isMobile;